$(document).ready(function() {


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * Sticky Navigation
    // *
    // *
    var $header = $("header.main");

    $(window).bind('scroll', function() {

		var myDistance = $(window).scrollTop();

   	    if (!$header.hasClass("fixed")) {
   	    	header_height = $("header.main").outerHeight();
   	    }

		if (myDistance > 10) {
			$header.addClass('fixed');
		}else {
			$header.removeClass('fixed');
		}
    });


	// * * * * * * * * * * * * * * * * * * * * * * * * *
	// * Slider Header
	// *
	// *
	if($(".slider").length >= 1){
		$(".slider ul").bxSlider({
			controls: false,
			pager: false,
			speed: 1000,
			pause: 8000,
			mode: 'fade',
			prevText: '<i class="fa fa-angle-left"></i>',
			nextText: '<i class="fa fa-angle-right"></i>',
			auto: ($(".slider ul li").length > 1) ? true : false
		});
	}


	// * * * * * * * * * * * * * * * * * * * * * * * * *
	// * Impressum
	// *
	// *
	$(".toggle-imprint").click(function(){
		$("#imprint").slideToggle();
		$('html, body').animate({
			scrollTop: $("#imprint").offset().top
		}, 500);
	});



	// * * * * * * * * * * * * * * * * * * * * * * * * *
	// * Scroll-To
	// *
	// * Smooth-Scroll to targets on page
	$('a[href*="#"]:not([href="#"])').click(function() {
		if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
			var target = $(this.hash);
			target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
			if (target.length) {
				$('html, body').animate({
					scrollTop: target.offset().top - 150
			}, 1000);
			return false;
			}
		}
	});


});
